<script>
import { required } from "vuelidate/lib/validators";
import Layout from "../../layouts/main";
import { userService } from "../../../helpers/user.service";
import Swal from "sweetalert2";

/**
 * Form-element component
 */
export default {
  components: { Layout },
  data() {
    return {
      title: "",
      userId: "",
      password: "",
      FullName: "",
      phone: "",
      user_role: "",
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
      UserData: userService
        .getUser(this.$router.currentRoute.params.id)
        .then((data) => {
          if (data.message.length == 0) {
            this.$router.push({
              path: "/404",
              redirect: { name: "Error-404" },
            });
          } else {
            this.UserData = data.message;
            this.userId = data.message.id;
            this.password = data.message.password;
            this.FullName = data.message.FullName;
            this.phone = data.message.phone;
            this.title = "کاربر " + data.message.FullName;
          }
        }),
      userRoles: userService.getRoles().then((data) => {
        this.userRoles = data.message;
      }),
    };
  },
  validations: {
    FullName: { required },
    phone: { required },
  },
  methods: {
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    EditSave() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.swalWithBootstrapButtons
          .fire({
            title: "آیا مطمئن هستید ؟",
            text: "از تنظیمات روی کاربر مطمئن هستید ؟",
            icon: "warning",
            confirmButtonText: "تایید",
            cancelButtonText: "لغو",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              userService
                .EditUser(this.userId, this.FullName, this.phone)
                .then((data) => {
                  this.swalWithBootstrapButtons.fire({
                    text: data.message,
                    icon: data.err,
                    confirmButtonText: "تایید",
                  });
                });
            } else {
              this.swalWithBootstrapButtons.fire({
                title: "لغو شد",
                text: "تنظیمات شما بر روی کاربر اعمال نشد",
                icon: "error",
                confirmButtonText: "تایید",
              });
            }
          });
      }
    },
    ChangePass() {
      const password = this.password;
      if (password) {
        userService.ChangePass(this.userId, password).then((data) => {
          this.swalWithBootstrapButtons.fire({
            text: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
        });
      } else {
        this.swalWithBootstrapButtons.fire({
          text: "لطفا ابتدا فیلد رمز را وارد کنید",
          icon: "error",
          confirmButtonText: "تایید",
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3 class="title">{{ title }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        id="example-fullName"
                        label="نام"
                        label-for="fullName"
                      >
                        <b-form-input
                          id="fullName"
                          :value="UserData.FullName"
                          v-model="FullName"
                          :class="{
                            'is-invalid': $v.FullName.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="!$v.FullName.required"
                          class="invalid-feedback"
                        >
                          نام الزامی است
                        </div>
                      </b-form-group>

                      <b-form-group
                        id="example-tel"
                        label="شماره همراه / نام کاربری"
                        label-for="tele"
                      >
                        <b-form-input
                          id="tele"
                          :value="UserData.phone"
                          type="tel"
                          name="tel"
                          v-model="phone"
                          :class="{
                            'is-invalid': $v.phone.$error,
                          }"
                        ></b-form-input>
                        <div v-if="!$v.phone.required" class="invalid-feedback">
                          شماره همراه / نام کاربری الزامی است
                        </div>
                      </b-form-group>

                      <div class="form-group row">
                        <label class="col-md-2 col-form-label">{{
                          $t("menuitems.users.list.roles")
                        }}</label>
                        <div class="col-md-12">
                          <select
                            class="custom-select"
                            v-model="UserData.user_role"
                          >
                            <option
                              v-for="(item, index) in userRoles"
                              :key="index"
                              :value="index"
                            >
                              {{ item }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12" class="row justify-content-end">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-info mb-3"
                        v-if="CheckAccessOnce('EditUser')"
                        @click="EditSave"
                        >ذخیره</a
                      >
                    </b-col>
                    <b-col md="12">
                      <div class="position-relative">
                        <b-form-group
                          id="example-password"
                          label-cols-sm="2"
                          label-cols-md="2"
                          label="تغییر رمز"
                          label-for="pwd"
                        >
                          <b-form-input
                            id="pwd"
                            type="password"
                            value=""
                            v-model="password"
                          ></b-form-input>
                        </b-form-group>
                        <a
                          href="javascript:void(0);"
                          class="col-sm-2 btn btn-danger col-md-1 position-absolute password-inline"
                          v-if="CheckAccessOnce('ChangePass')"
                          @click="ChangePass"
                          ><i class="mdi mdi-textbox-password"></i
                        ></a>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>